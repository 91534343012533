<template>
  <b-field label="PIC">
    <b-autocomplete
      v-model="searchUser"
      :data="listUser"
      placeholder="Search PIC"
      field="fullName"
      :loading="isFetchingUser"
      :check-infinite-scroll="true"
      icon-right="account"
      @typing="getUserList"
      @select="selectUser"
      @infinite-scroll="getMoreUserList"
      :disabled="isEditing && notUpdatable"
    >
      <template slot-scope="props">
        <div class="media">
          <div class="media-left">
            <div v-if="props.option.profilePictureUrl">
              <img
                class="request-profile-picture"
                width="32"
                :src="props.option.profilePictureUrl"
              />
            </div>
            <div v-else>
              <img
                class="request-profile-picture"
                width="32"
                :src="
                  props.option.gender === 'F'
                    ? '/images/default-profile-picture-female.png'
                    : '/images/default-profile-picture-male.png'
                "
              />
            </div>
          </div>
          <div class="media-content">
            {{ props.option.fullName }}
            <br />
            <small>
              {{ props.option.positionName ? props.option.positionName : '-' }}
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>
<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      data: false,
    },
    listUser: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchUser: '',
    }
  },

  methods: {
    /**
     * Emit user data from database
     * @param event - listener for get user method
     */
    getUserList(event) {
      this.$emit('getUser', event)
    },

    /**
     * Emit select user method from parent component
     * @param event - listener for select user method
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit more user data from database
     * @param event - listener for get more user method
     */
    getMoreUserList(event) {
      this.$emit('getMoreUser', event)
    },
  },
}
</script>
